import { v4 as uuidv4 } from 'uuid';

// Take number of text pages as intended length, and add any missing images if the page exists.
// Page numbers are restarted from 1, subsequent page numbers are moved up to fill any gaps/missing pages
// Missing images are filled in with a url pointing to an error image
// Extra images without a matching page are discarded
export function matchPageLengths(pages, images) {
  // replace missing images
  let resultPages = {}
  let resultImages = {};
  let i = 1;

  for (const [key, value] of Object.entries(pages)) {
    const currentPage = key.replace('page_', "");
    // align page and image numbers to deal with weird gaps
    resultPages[`page_${i}`] = value;

    if (!images.hasOwnProperty(`image_${currentPage}`)) {
      console.log(`missing image ${currentPage}, replacing with error image`);
      resultImages[`image_${i}`] = {
        url: "/img/missing_page_image.png",
        caption: "Image not found - image generation error"
      }
    } else {
      resultImages[`image_${i}`] = images[`image_${currentPage}`];
    }
    i++;
  }
  return [resultPages, resultImages];
}

export function separatePageAndImageList(storyData) {
  let pages = Object.fromEntries(Object.entries(storyData).filter(([key]) => key.includes('page')));
  let images = Object.fromEntries(Object.entries(storyData).filter(([key]) => key.includes('image')));

  // Disable client-side page length matching for now - TODO fix ordering issue
  // return matchPageLengths(pages, images);
  return [pages, images];
}

export function wait(delay) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

export function getDisplayStatus(bool) {
  return bool ? "flex" : "none";
}

export function getUserKey() {
  let userKey = localStorage.getItem("userKey");
  if (!userKey) {
    userKey = uuidv4();
    localStorage.setItem("userKey", userKey);
  }
  return userKey;
}

export function setEventKey(eventKey) {
  localStorage.setItem("eventKey", eventKey);
}

//this should be set via a query string parameter in the URL provided in the QR code
//this is just dodgied up to help me test
export function getEventKey() {
  let eventKey = localStorage.getItem("eventKey");
  return eventKey;
}

export function getPreviousFormInputs() {
  let formPlaceholders = JSON.parse(localStorage.getItem("formPlaceholders"));
  if (!formPlaceholders || !formPlaceholders.hasOwnProperty("storyType")) {
    formPlaceholders = {
      storyType: "fairytale",
      imageStyle: "disney",
      model: "claude",
      protagonistName: "Prince Flynn",
      protagonistDesc: "A prince with blonde hair and a fancy royal outfit",
      villainName: "Fluffy McFluffles",
      villainDesc: "A giant evil rabbit with pitch black fur and red eyes",
      npcName: "Alice",
      npcDesc: "A beautiful fairy wearing a sparkling dress",
      customImageStyle: "",
      themeName: ""
    }
  }
  return formPlaceholders;
}

export function stashFormInputs(data) {
  data.customImageStyle = "";
  localStorage.setItem("formPlaceholders", JSON.stringify(data));
}